import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CONTENT} from '../../../content-management/content';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PolicyDetails} from '../../../models/policy-details';
import {BillingPaymentService} from '../../services/billing-payment.service';
import {FileNewClaimService} from '../../services/file-new-claim.service';
import * as _ from 'lodash';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {MatDialog} from '@angular/material/dialog';
import {DynamicTermsComponent} from '../file-new-claim/dynamic-terms/dynamic-terms.component';
import {CommonService} from '../../services/common.service';
import {FormValidationConstants} from '../../../shared/constants/form-validation-constants';
import {EncrDecrService} from '../../../core/services/encr-decr.service';
import {GtmEventsService} from '../../services/gtm-events.service';
import {ClaimErrorModalComponent} from './claim-error-modal/claim-error-modal.component';
import {DateAdapter} from '@angular/material/core';
import {MAT_DATE_FORMATS,MAT_DATE_LOCALE } from '@angular/material/core';
import {MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
      dateInput: 'MM/DD/YYYY',
    },
    display: {
      dateInput: 'MM/DD/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
};

@Component({
  selector: 'app-file-new-claim',
  templateUrl: './file-new-claim.component.html',
  styleUrls: ['./file-new-claim.component.scss'],
 providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class FileNewClaimComponent implements OnInit {
  public content = CONTENT;
  public startNewClaimForm: UntypedFormGroup;
  public policyDetailsList: PolicyDetails[] = [];
  public images: any = [];
  public uploadedFiles: File[] = [];
  public hideTextArea = false;
  public routingConstants = ROUTINGCONSTANTS;
  public renewalNumber: string;
  public minDate: Date;
  public maxDate: Date;
  public selectedPolicy: string;
  public reasons: string[] = ['Wellness', 'Injury or Illness'];
  public currentPage = 1;
  public itemsPerPage = 5;
  public totalItems = 0;
  public formValidation = FormValidationConstants;
  public nonWhiteSpaceRegex = new RegExp('\\S');
  public amount: string;
  public policyMigrated: any;
  public displayProgressSpinner = 0;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private fileNewClaim: FileNewClaimService,
              private formBuilder: UntypedFormBuilder,
              private cd: ChangeDetectorRef,
              private gtmEventsService: GtmEventsService,
              private billingPaymentService: BillingPaymentService,
              private dialog: MatDialog,
              private commonService: CommonService,
              private dateAdapter: DateAdapter<Date>,
              private encDcrService: EncrDecrService) {
  }

  ngOnInit() {
    this.getInitialLoad();
  }

  getInitialLoad() {
    if (sessionStorage.getItem('policyList')) {
      if (this.displayProgressSpinner !== 0) {
        this.subtractSpinner();
      }
      this.minDate = new Date();
      this.maxDate = new Date();
      this.getPolicyDetailsList();
      this.getPolicyNumberFromRoute();
      // this.createForm();
    } else {
      if (this.displayProgressSpinner == 0) {
        this.addSpinner();
      }
      setTimeout(() => {
        this.getInitialLoad();
      }, 250);
    }

  }

  public getPolicyNumberFromRoute() {
    this.route.params.subscribe(params => {
      if (params['policyNumber'] && params['renewalNumber']) {
        this.selectedPolicy = this.encDcrService.get(params['policyNumber']);
        this.renewalNumber = params['renewalNumber'];
        this.createForm();
      } else {
        this.createForm();
      }
    });
  }

  createForm() {
    this.addSpinner();
    this.startNewClaimForm = this.formBuilder.group({
      petName: [this.selectedPolicy, [Validators.required]],
      reasonForVisit: ['', [Validators.required]],
      itemizedInvoices: ['', [Validators.required]],
      treatmentDateFrom: ['', [Validators.required]],
      treatmentDateTo: ['', [Validators.required]],
      hospitalName: ['', [Validators.required, Validators.pattern(this.nonWhiteSpaceRegex)]],
      diagnosisDetails: [''],
      termsAndConditions: [false, [Validators.requiredTrue]],
    });
    setTimeout(() => {
      this.subtractSpinner();
    }, 1000);
  }

  hideDiagnosisField() {
    if (this.getFormValue('reasonForVisit').length === 0 || (this.getFormValue('reasonForVisit').length === 1 && this.getFormValue('reasonForVisit')[0] === 'Wellness')) {
      this.startNewClaimForm.controls['diagnosisDetails'].clearValidators();
      this.startNewClaimForm.controls['diagnosisDetails'].updateValueAndValidity();
      this.startNewClaimForm.controls['diagnosisDetails'].reset();
      this.hideTextArea = false;
    } else {
      this.startNewClaimForm.controls['diagnosisDetails'].setValidators([Validators.required, Validators.pattern(this.nonWhiteSpaceRegex)]);
      this.startNewClaimForm.controls['diagnosisDetails'].updateValueAndValidity();
      this.hideTextArea = true;
    }
  }


  changeCheck(event) {
    if (event.checked) {
      this.startNewClaimForm.controls['termsAndConditions'].setValue(true);
    } else {
      this.startNewClaimForm.controls['termsAndConditions'].setValue('');
    }
  }


  getFormValue(formControlName) {
    return this.startNewClaimForm.value[formControlName];
  }

 async fileUploads(event) {
    const files = event.target.files;
    if (files) {
      try{
        const promises = [];
        for (let file of files) {
          const reader = new FileReader();
          const format = file.name.substring(file.name.lastIndexOf('.') + 1);
          if (files.length <= 100) {
            if (format.toLowerCase() === 'pdf' || format.toLowerCase() === 'jpg' || format.toLowerCase() === 'png'
              || format.toLowerCase() === 'tif' || format.toLowerCase() === 'tiff' || format.toLowerCase() === 'jpeg') {
              const promise = new Promise<void>((resolve, reject)=>{
                reader.onload = () => {
                  file= this.validateFile(file);
                  this.images.push(file);
                  this.uploadedFiles.push(file);
                  this.totalItems++;
                  resolve();
                };
                reader.onerror = () => {
                  reject(new Error('Failed to upload files'));
                };
                reader.readAsDataURL(file);
              });
              promises.push(promise);
            } else {
              this.commonService.setValidationErrorModal(format + ' ' + 'format is not supported');
              break;
            }
          } else {
            this.commonService.setValidationErrorModal('Number of attached files exceeds maximum limit of 100');
            break;
          }
        }
        await Promise.all(promises);
        this.cd.markForCheck();
        event.target.value = ''; 
      }
      catch(_){
        
      }
    }
  }

  validateFile(file) {
    let count = 0;
    let isFileExist = false;
    const inputFileArray = file.name.split('.');
    // filtering the selected file
    let resultArray = this.images.filter(fileObj => {
      const fileArray = fileObj.name.split('.');
      const fileNameArray = fileArray[0].split('_');
      const existFilename = fileNameArray[0] + '.' + fileArray[1];
      if (existFilename === file.name) {
        return fileObj;
      }
    });
    // sorting the filtered list
    resultArray = resultArray.sort(function(a, b) {
      const textA = a.name;
      const textB = b.name;
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    this.images.forEach(function(fileExist) {
      if (fileExist.name.indexOf(file.name) != -1) {
        const fileArray = fileExist.name.split('.');
        const fileNameArray = fileArray[0].split('_');
        if (fileNameArray[1] && fileNameArray[1] != undefined) {
          count = Number(fileNameArray[1]);
        }
        file = new File([file], fileNameArray[0] + '_' + (count + 1) + '.' + fileArray[1], {type: file.type});
      }
    });
    // validate whether renamed file is exist or not
    resultArray.forEach(function(fileObject) {
      if (fileObject.name.indexOf(file.name) != -1) {
        isFileExist = true;
      }
    });
    // if renamed file is exist in list, then rename again
    if (isFileExist) {
      count = resultArray.length;
      file = new File([file], inputFileArray[0] + '_' + (count) + '.' + inputFileArray[1], {type: file.type});
    }


    return file;
  }

  deleteImage(image: any) {
    const index = this.images.indexOf(image);
    this.images.splice(index, 1);
    this.uploadedFiles.splice(index, 1);
    this.totalItems--;

    const startRecord = (this.currentPage -1) * this.itemsPerPage + 1;
    const endRecord = Math.min(this.currentPage * this.itemsPerPage, this.totalItems);
    if(this.currentPage > 1 && (startRecord > this.totalItems || endRecord === startRecord - 1)){
      this.currentPage--;
    }
  }

  preventClick(e: Event) {
      e.stopPropagation();
      e.preventDefault();
    return;
  }

  openDialog(e: Event, isLink) {
    if (this.startNewClaimForm.value.termsAndConditions && !isLink) {
        e.stopPropagation();
        if(isLink) { e.preventDefault(); } 
      const dialogRef = this.dialog.open(DynamicTermsComponent, {
        disableClose: true,
        data: this.startNewClaimForm.value.termsAndConditions
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.startNewClaimForm.patchValue({termsAndConditions: result});
          this.startNewClaimForm.get('termsAndConditions').markAsDirty();
        }else {
          this.startNewClaimForm.patchValue({termsAndConditions: false});
        }
      });
    }
  }

  setFormData() {
    const formData = new FormData();
    const dataFrom = new Date(this.getFormValue('treatmentDateFrom'));
    const trDateFrom = (dataFrom.getMonth() + 1) + '/' + dataFrom.getDate() + '/' + dataFrom.getFullYear();
    const dataTo = new Date(this.getFormValue('treatmentDateTo'));
    const trDateTo = (dataTo.getMonth() + 1) + '/' + dataTo.getDate() + '/' + dataTo.getFullYear();
    this.addSpinner();
    formData.append('diagnosis', this.getFormValue('diagnosisDetails') ? this.getFormValue('diagnosisDetails') : '');
    formData.append('hospitalClinicName', this.getFormValue('hospitalName'));
    formData.append('injuryOrIllness', this.getFormValue('reasonForVisit'));
    formData.append('totalClaimSubmitted', this.getFormValue('itemizedInvoices'));
    formData.append('treatmentDateFrom', trDateFrom);
    formData.append('treatmentDateTo', trDateTo);
          _.forEach(this.uploadedFiles, (file: File) => {
      formData.append('filename', file, file.name);
    });

    return formData;
  }

  onSubmit() {
    this.policyMigrated = false;
    this.fileNewClaim.policyMigration(this.startNewClaimForm.value.petName).subscribe({next: (data:any) =>
      {
        if(typeof data === 'boolean')
        {
          this.policyMigrated = data;
        }
        this.claimsSubmit();
      },
      error: (err) => 
      {
        this.policyMigrated = false;
        this.claimsSubmit();
      }
    });
  }

  claimsSubmit() {
      this.fileNewClaim.upload(this.startNewClaimForm.value.petName, this.getRenewal(this.startNewClaimForm.value.petName), this.setFormData(), this.policyMigrated).subscribe({
      next: (response) => {
        if (response.code === 'OK') {
          this.setGtmForClaimSubmission(this.startNewClaimForm.value.petName);
          this.commonService.setConfirmationModal({header: this.content.claimTermsAndConditions.confirmation}).subscribe({
            next: () => this.router.navigate([this.routingConstants.home])
          });
        } else if (response.code === 'UnsupportedMediaType') {
          const files = response.docs;
          if (files != null) {
            const fileNames = files.split('~');
            this.dialog.open(ClaimErrorModalComponent, {
              disableClose: true,
              data: {
                title: this.content.validations.registerAccount.error,
                header: 'We encountered an issue when uploading the following file(s):',
                files: fileNames,
                headers1: 'Please check the file(s) and try your submission again.',
                content: this.content.claimTermsAndConditions.corruptFiles
              }
            });
          }
        } else {
          this.commonService.setValidationErrorModal(this.content.claimTermsAndConditions.error);
        }

      },
      complete: () => this.subtractSpinner(),
      error: (error) => {
        this.subtractSpinner();
        const fileNames = this.fileNewClaim.fileList;
        this.dialog.open(ClaimErrorModalComponent, {
          disableClose: true,
          data: {
            title: this.content.validations.registerAccount.error,
            header: 'We encountered an issue when uploading the following file(s):',
            files: fileNames,
            headers1: 'Please check the file(s) and try your submission again.',
            content: this.content.claimTermsAndConditions.corruptFiles
          }
        });
      }
    });
  }


  claimSubmissionError(error) {
    this.setGtmForClaimSubmissionError(this.startNewClaimForm.value.petName);
    this.subtractSpinner();
  }

  setGtmForClaimSubmission(user) {
    const gtmTag = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Claim Submission Button Click',
      eventLabel: 'Claim Submission Button',
      eventValue: `${user} submitted a claim`,
      userId: sessionStorage.getItem('userId')
    };
    this.gtmEventsService.pushGTMTags(gtmTag);
  }

  setGtmForClaimSubmissionError(user) {
    const gtmTag = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Claim Submission Button Click Error',
      eventLabel: 'Claim Submission Button for Error',
      eventValue: `${user} submitted a claim which has errors in these files`,
      userId: sessionStorage.getItem('userId')
    };
    this.gtmEventsService.pushGTMTags(gtmTag);
  }

  getRenewal(policyNumber: string) {
    return this.policyDetailsList.reduce((acc, curr) => policyNumber === curr.policyNumber ? curr.renewalNumber : acc, null);
  }


  getPolicyDetailsList() {
    // this.billingPaymentService.getPolicyDetailsList().subscribe(data => {
    //   if (data && data.policyDetailsList) {
    //     this.policyDetailsList = data.policyDetailsList;
    //   }
    // });
    const sessionPolicyList = JSON.parse(sessionStorage.getItem('policyList'));
    const policyList: any = [];
    sessionPolicyList.filter(policyDetail => {
      // if (policyDetail.policyStatus != "Cancelled" && policyDetail.policyStatus != "Expired") {
      policyList.push(policyDetail);
      // }
    });
    this.policyDetailsList = policyList;
  }

  addSpinner() {
    this.displayProgressSpinner++;
    this.cd.markForCheck();
  }

  subtractSpinner() {
    this.displayProgressSpinner--;
    this.cd.markForCheck();
  }

}
