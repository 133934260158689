<div class="container">
  <div class="row">
    <!-- <div class="col-xs-12 sweepstakes">
        <h4>{{content.homePage.homePageFooter.sweepsTitle}}<strong>{{content.homePage.homePageFooter.sweepsTitle1}}</strong>
        </h4>
        <p>{{content.homePage.homePageFooter.sweepsCopy1}}</p>
        <p>{{content.homePage.homePageFooter.sweepsCopy2}}<a (click)="navigateToQEC()" href="javascript:void(0);" id="anchorLink-enrollNewPet">{{content.homePage.homePageFooter.sweepsQuote}}</a> {{content.homePage.homePageFooter.sweepsCopy3}}
            <a (click)="navigateToOfficialRules()" href="javascript:void(0);" id="anchorLink-officialRules">{{content.homePage.homePageFooter.sweepsLink}}</a>
        </p>
    </div> -->
    <!-- <div *ngIf="bannerTextHtml" class="col-xs-12 sweepstakes">       -->
      <!-- <h4>{{content.homePage.homePageFooter.sweepsTitle}}</h4> -->
      <!-- <div [innerHTML]="bannerTextHtml"></div> -->
      <!-- <p>{{content.homePage.homePageFooter.sweepsCopy1}}</p>
      <p>{{content.homePage.homePageFooter.sweepCopy7}} <a (click)="navigateToQECSweepStakes()" href="javascript:void(0);"
                                                                     id="anchorLink-qecurl">{{content.homePage.homePageFooter.sweepCopy8}}</a></p>
      <p>{{content.homePage.homePageFooter.sweepsCopy4}}<a (click)="navigateToOfficialRules()" href="javascript:void(0);"
                                                           id="anchorLink-officialRules">{{content.homePage.homePageFooter.sweepsCopy5}}</a></p> -->
<!-- 
      <p>{{content.homePage.homePageFooter.sweepsCopy6}}</p>
-->
    <!-- </div> -->
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-12">
      <ul class="media-list" *ngIf="iconObj && dynamicContent">
        <li *ngIf="!this.shouldHideAddPetLink">
          <div class="media-icon">
            <!-- <mat-icon svgIcon="enroll_icon"></mat-icon> -->
            <img id="enroll" src="{{iconObj?.pawIcon}}" width="35px" alt="enroll icon" />
          </div>
          <div class="media-content">
            <h2 class="media-heading textColorBlue"><a id="anchorLink-enrollNewPet" href="javascript:void(0)" (click)="navigateToQEC()">{{dynamicContent.homePage.homePageFooter.addPet}}</a></h2>
            <p class="font-weight-light">{{dynamicContent.homePage.homePageFooter.addpetInfo}}</p>
          </div>
        </li>
        <li>
          <div class="media-icon">
            <!-- <mat-icon svgIcon="arrowR_icon"></mat-icon> -->
            <img id="refer" src="{{iconObj?.refer}}" width="35px" alt="refer icon" />
          </div>
          <div class="media-content">
            <h2 class="media-heading textColorBlue"><a id="hyperLink-refferal" href="{{hyperLinkConstants.homePage.referral}}" target="_blank" (click)="navigateToReferrals()">{{dynamicContent.homePage.homePageFooter.referAFriendandFamily}}</a></h2>
            <p class="font-weight-light">{{dynamicContent.homePage.homePageFooter.friendandFamilyInfo}}</p>
          </div>
        </li>
        <li>
          <div class="media-icon">
            <!-- <mat-icon svgIcon="call_icon"></mat-icon> --> 
            <img id="helipline" src="{{iconObj?.helpLine}}" width="35px" alt="helipline icon" />
          </div>
          <div class="media-content">
            <h2 class="media-heading textColorBlue">
              <a id="img-vetHelpLine" href="{{dynamicContent.homePage.homePageFooter.vetHelpLineLink}}" target="_blank" (click)="navigateToVetHelpLine()">
                <img alt="vet-helpline" src="{{iconObj?.vethelpLineLogo}}"/></a>
            </h2>
            <p class="font-weight-light">{{dynamicContent.homePage.homePageFooter.VethelplineInfo}}</p>
          </div>
        </li>
        <li>
          <div class="media-icon">
            <!-- <mat-icon svgIcon="pills_icon"></mat-icon> -->
            <img id="prescription" src="{{iconObj?.prescription}}" width="35px" alt="prescription icon" />
          </div>
          <div class="media-content">
            <div class="media-heading textColorBlue" style="cursor: default;">
              
                <img alt="Get Rx Pet meds for less at Walmart or Sam's Club" width="160" src="{{iconObj?.petRxExpressLogo}}"/>
            </div>
           <p [innerHTML]="dynamicContent.homePage.homePageFooter.walmartInfo" class="font-weight-light"></p>
          </div>
        </li>
        <li *ngIf="!isECP">
          <div class="media-icon">
            <!-- <mat-icon svgIcon="money_icon"></mat-icon> -->
            <img id="money" src="{{iconObj?.money}}" width="35px" alt="money icon" />
          </div>
          <div class="media-content">
            <h2 class="media-heading textColorBlue ecp">
              <a id="anchorLink-fastCash" [href]="routingConstants.electronicClaims" (click)="navigateToFastCash()">{{dynamicContent.homePage.homePageFooter.fastCashHome}}</a></h2>
          </div>
        </li>
        <li>
          <div class="media-icon">
            <!-- <mat-icon svgIcon="companion_icon"></mat-icon> -->
            <img id="lightBulb" src="{{iconObj?.lightBulb}}" width="35px" alt="lightBulb icon" />
          </div>
          <div class="media-content">
            <h2 class="media-heading textColorBlue"><a id="hyperLink-newsLetter" href="{{hyperLinkConstants.homePage.newsLetter}}" target="_blank"
                                                       (click)="navigateToNewsLetter()">{{dynamicContent.homePage.homePageFooter.companion}}</a>
            </h2>
          </div>
        </li>
        <li>
          <div class="media-icon">
            <!-- <mat-icon svgIcon="PHZ_icon"></mat-icon> -->
            <img id="heart" src="{{iconObj?.heart}}" width="35px" alt="heart icon" />
          </div>
          <div class="media-content">
            <h2 class="media-heading textColorBlue"><a id="hyperLink-articles" href="{{hyperLinkConstants.homePage.articles}}" target="_blank"
                                                       (click)="navigateToArticles()">{{dynamicContent.homePage.homePageFooter.phz}}</a></h2>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-2">
    </div>
    <div class="col-lg-5 col-md-5 d-none d-sm-block d-xs-block justify-content-end">
      <div class="help-links" *ngIf="dynamicContent">
        <h3>{{dynamicContent.homePage.homePageFooter.helpInfo.heading}}</h3>
        <ul class="footer-links">
          <li id="fileNewClaim"><a class="file-new-claim-link" href="javascript:void(0);" (click)="navigateToFileNewClaim()">{{dynamicContent.homePage.homePageFooter.helpInfo.fileNewClaim}}</a></li>
          <li id="claimStatus"><a class="file-new-claim-link" href="javascript:void(0);" (click)="navigateToClaimStatus()">{{dynamicContent.homePage.homePageFooter.helpInfo.claimStatus}}</a></li>
          <li id="coverageDetails"><a class="file-new-claim-link" href="javascript:void(0);" (click)="navigateToCoverageDetails()">{{dynamicContent.homePage.homePageFooter.helpInfo.coverageDetails}}</a></li>
          <li id="billingInfo"><a class="file-new-claim-link" href="javascript:void(0);" (click)="navigateToBillingInfo()">{{dynamicContent.homePage.homePageFooter.helpInfo.billingInfo}}</a></li>
          <li id="contactInfo"><a class="file-new-claim-link" href="javascript:void(0);" (click)="navigateToBillingInfo()">{{dynamicContent.homePage.homePageFooter.helpInfo.contactInfo}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
