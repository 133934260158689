<div class="container pb-5">
  
  <div class="pets-title">
    <h3><strong>My pets</strong></h3>
  </div>

  <hr class="sectionDivider"/>

  <div class="pet-slider pt-3">
    <carousel (activeSlideChange)="onActiveSlideChanged($event)" #petCarousel [interval]="false"
      [showIndicators]="false" class="slide-animated">
      
      <slide *ngFor="let pet of myPets; let idx = index">
        <div class="slider_content">
          <div class="row">
            <div class="mx-auto slider-content-align">
              
              <a *ngIf="hideIndicators" href="javascript:void(0)" (click)="petCarousel.previousSlide(true)" tabindex="0"
                aria-label="Previous slide">
                <mat-icon *ngIf="hideIndicators" class="carousel_icon carousel_left">chevron_left</mat-icon>
              </a>

              <div class="left--section">
                
                <!-- <app-pet-profile-picture *ngIf="petSepcies" imageAlt="dogInfo"
                  imageId="img-blue-dog-{{idx}}" [imageUrl]="iconObj.canineIcon">
                </app-pet-profile-picture>

                <app-pet-profile-picture *ngIf="!petSepcies" imageAlt="catInfo"
                  imageId="img-blue-cat-{{idx}}" [imageUrl]="iconObj.felineIcon">
                </app-pet-profile-picture> -->
                <app-pet-profile-picture [imageId]="'img-dog-home-'+idx" [imageUrl]="petSpeciesUrl">
                </app-pet-profile-picture>

                <div>
                  <h4>{{ pet.pet.name | titlecase }}</h4>
                  
                  <p class="no_bottom_margin">
                    Policy # <b>{{ pet.policyNumber }}</b>
                  </p>

                  <p class="no_bottom_margin">
                    Protected Since: <b>{{ pet.protectedSince | date }}</b>
                  </p>

                  <p class="no_bottom_margin">
                    Gender: <b>{{ pet.pet.sex | titlecase }}</b>
                  </p>

                  <p class="no_bottom_margin">
                    Age: <b>{{ pet.pet.age }}</b>
                  </p>

                </div>
              </div>

              <a *ngIf="hideIndicators" href="javascript:void(0)" (click)="petCarousel.nextSlide(true)" tabindex="0"
                aria-label="Next slide">
                <mat-icon class="carousel_icon carousel_right">chevron_right</mat-icon>
              </a>

            </div>
          </div>
          <hr />

          <div class="row">
            <div [ngClass]="{'columnPosition leftPosition': isMPIPolicy && isClaimLoaded, 'col-md-6': !isMPIPolicy || !isClaimLoaded }">
            <!-- <div class="columnPosition leftPosition"> -->
              <div class="coverage_section">
                <div *ngIf="!isMPIPolicy" class="text-center mx-auto">
                  
                  <h3>{{ pet.pet.name | titlecase }}'s coverage</h3>
                  <p *ngFor="let planName of pet.planNames">{{ planName | titlecase }}</p>

                </div>
                <div *ngIf="isMPIPolicy" class="text-center mx-auto">
                  
                  <h3>{{ pet.pet.name | titlecase }}'s coverage</h3>
                  <p>{{ pet.coverage }}</p>

                </div>

                <br />

                <p>Status:<span class="float-end">{{ pet.policyStatus }}</span></p>

                <p>Policy#:<span class="float-end">{{ pet.policyNumber }}</span></p>

                <p>Effective:<span class="float-end">{{ pet.effectiveDate | date }}</span></p>

                <p>Expires:<span class="float-end">{{ pet.expirationDate | date }}</span></p>

                <p>Deductible:<span class="float-end">{{ setDeductible(pet.deductible) }}</span></p>

                <p>Medical Exclusion:<span class="float-end">{{ pet.medicalExclusionStatus }}</span></p>

                <p *ngIf="pet.reimbursementlevel">Reimbursement Level:<span
                    class="float-end">{{ pet.reimbursementlevel }}%</span></p>

                <hr />

                <p class="no_bottom_margin"><b
                    *ngIf="pet.policyStatus !== 'Cancelled' && pet.policyType !== 'Group Payroll' && pet.policyStatus !== 'Expired'">
                    <a class="make-payment" (click)="goToMakeAPayment(pet.policyNumber, pet.renewalNumber)"
                      href="javascript:void(0);">Make
                      a payment</a></b>
                </p>
                
                <br
                  *ngIf="pet.policyStatus !== 'Cancelled' && pet.policyType !== 'Group Payroll' && pet.policyStatus !== 'Expired'" />

                <p class="no_bottom_margin"><b><a class="view-manage-policy" href="javascript:void(0);"
                      (click)="goToManagePolicy(pet.policyNumber)">Manage policy</a></b></p>

                <br />

                <p class="no_bottom_margin"><b><a (click)="goToClaims(pet.pet.name, pet.policyNumber)"
                      href="javascript:void(0);">View past claims</a></b></p>

                <hr />

                <div class="d-block text-center">
                  <app-new-claim-button [policyNumber]="pet.policyNumber" [renewalNumber]="pet.renewalNumber"
                    [focusable]="currentSlideNumber===idx"></app-new-claim-button>
                </div>
              </div>
            </div>
              
            <div class="col-12 d-block d-md-none" *ngIf="isMPIPolicy && isClaimLoaded">
            <!-- <div class="col-12 d-block d-md-none" *ngIf="isClaimLoaded"> -->
              <hr class="pet-hr">
            </div>
            
            <div class="columnPosition middlePosition pet-div-border-left" *ngIf="isMPIPolicy && isClaimLoaded && coverageDataArr">
            <!-- <div class="columnPosition middlePosition pet-div-border-left"> -->
              <!-- <div class="col-md-12 mx-auto topPadding"  *ngIf="isClaimLoaded  && coverageDataArr"> -->
                <div class="col-md-12 mx-auto topPadding">
                <app-benefits-deductible-gauge [policy]="pet" [policyClaims]="policyClaims" [isMyPetsPage]="true" [coverageData]="coverageDataArr">
                  </app-benefits-deductible-gauge>

              </div>

              <!-- <div *ngIf="isMPIPolicy"> -->
                <div *ngIf="showMore">
                  <app-my-pet-coverage [policy]="pet" [policyClaims]="policyClaims" [coverageData]="coverageDataArr" >
                  </app-my-pet-coverage>
                </div>

                <div class="row div-top-margin" (click)="showMore = !showMore">
                  <div class="benefitLink nopadding">{{showMore ? 'View less' : 'View details'}}
                    <i *ngIf="!showMore" class="margin-left arrow down"></i>
                    <i *ngIf="showMore" class="margin-left arrow up"></i>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <p style="margin:5%;font-size:smaller;" [innerHTML]="disclosureTextHtml"></p>
                  </div>
                </div>
              <!-- </div> -->
            </div>

            <div class="col-12 d-block d-md-none">
              <hr class="pet-hr">
            </div>

            <div class="pet-div-border-left" [ngClass]="{'columnPosition rightPosition': isMPIPolicy && isClaimLoaded, 'col-md-6': !isMPIPolicy || !isClaimLoaded }">
            <!-- <div class="pet-div-border-left columnPosition rightPosition"> -->
              <div class="col-12 topPadding">
                <div>

                  <app-my-pet-payment *ngIf="canEditPayment(pet)" [slideIndex]="idx" [paymentInfo]="getPaymentInfo(pet)"
                    [myPet]="pet" [isMPIPolicy]=" isMPIPolicy"></app-my-pet-payment>

                </div>
                
                <hr *ngIf="canEditPayment(pet)" />
                
                <div class="link_section">
                  <div class="text-center mx-auto">
                    <h3>Forms and documents</h3>
                  </div>
                  <br />
                  
                  <p class="no_bottom_margin"><a class="view-manage-policy"
                      (click)="getPreFilledForm(pet.policyNumber, pet.renewalNumber)"
                      href="javascript:void(0);">Download
                      pre-filled claim form</a></p>

                  <br />

                  <p class="no_bottom_margin"><a class="view-manage-policy" (click)="goToViewDocuments()"
                      tabindex="{{currentSlideNumber===idx ? 0 : -1}}">View my policy documents</a></p>
                  
                </div>
              </div>

              <br />
            </div>
          </div>
        </div>
      </slide>
    </carousel>
    <br />
  </div>
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>