import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export enum FeatureTogglesStatus{
  EnableNewClaimsForm = 'EnableNewClaimsForm'
}
@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  constructor() { }

  getIsEnableFeatureToggle()
  {
    let _featureStatus: any;
    let content = JSON.parse(sessionStorage.getItem('portalContenful'));
    switch (environment.env) {
      case 'stage':
        _featureStatus = content.featureToggles.STAGING;
        break;
      case 'prod':
        _featureStatus = content.featureToggles.PRODUCTION;
        break;
      default:
        _featureStatus = content.featureToggles.TESTING;
        break;
    }
    return _featureStatus;
  }

  isEnableNewClaimsForm():boolean{
    let _status = this.getIsEnableFeatureToggle();
    return _status[0] === FeatureTogglesStatus.EnableNewClaimsForm;
  }
}
