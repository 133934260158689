<div class="container pb-5">
  <div class="container-title">
    <h1 class="container-title">{{ content.homePage.welcome }} <strong> {{ userName | titlecase }}!</strong></h1>
  </div>
  
  <hr style="border: 1px solid grey" />
  
  <div *ngIf="policyDetailsList" class="home_slider">
    <carousel (activeSlideChange)="onActiveSlideChanged($event)" #petCarousel [interval]="false" [showIndicators]="false" class="slide-animated">
      <slide *ngFor="let data of policyDetailsList; let idx = index">
        <div class="row">
          <div class=" text-left"
            [ngClass]="{'col-lg-4 col-md-4 sol-sm-12': rxclaimOptin, 'col-lg-6 col-md-6 sol-sm-12': !rxclaimOptin }">
            <div>
              <div class="text-center">
                <div [ngClass]="{'col-md-12': rxclaimOptin, 'col-md-12': !rxclaimOptin }">
                  <div class="item">
                    <a *ngIf="policyDetailsList?.length > 1" href="javascript:void(0)"
                      (click)="petCarousel.previousSlide(true)" aria-label="Previous slide" class="carousel-nav prev">
                      <mat-icon class="carousel_icon carousel_left">chevron_left</mat-icon>
                    </a>
                    
                    <div class="carousel-content" [ngClass]="{'carousel-content-rxclaimoptin': rxclaimOptin}">
                      <a (click)="goToPets()" tabindex="{{currentSlideNumber===idx ? '0' : '-1'}}"
                        aria-label="pet-image" href="javascript:void(0);">
                        <app-pet-profile-picture [imageId]="'img-dog-home-'+idx" [imageUrl]="petSpeciesUrl">
                        </app-pet-profile-picture>
                      </a>

                      <h3>{{ sliderModel?.policy?.pet?.name | titlecase }}</h3>
                      <div class="policy-info-cobranding-logo" *ngIf="sliderModel?.policy?.cobrandingStatus" [ngStyle]="{'background-image': 'url(' + policyInfoLogoUrl + ')' }">
                      </div>
                      <p>Policy#: <b>{{ sliderModel?.policy?.policyNumber }}</b></p>
                      
                      <hr class="m-2">
                      <p><b>{{ sliderModel?.policy?.coverage | titlecase }}</b></p>

                      <hr class="m-2">
                      <p>Status: <b>{{ sliderModel?.policy?.policyStatus | titlecase }}</b>
                      </p>

                      <p *ngIf="isDisplayEffective(sliderModel?.policy)">Effective: <b>{{ sliderModel?.policy?.effectiveDate | date:'M/d/yyyy' | titlecase }}</b></p>

                      <br />

                      <div *ngIf="sliderModel?.claim?.claimNumber" class="d-block">
                        <app-new-claim-button [focusable]="currentSlideNumber===idx"
                          [policyNumber]="sliderModel?.policy?.policyNumber"
                          [renewalNumber]="sliderModel?.claim?.renewal" id="button-claim-{{idx}}">
                        </app-new-claim-button>
                      </div>
                    </div>

                    <a *ngIf="policyDetailsList?.length > 1" href="javascript:void(0)"
                      (click)="petCarousel.nextSlide(true)" aria-label="Next slide" class="carousel-nav next">
                      <mat-icon class="carousel_icon carousel_right">chevron_right</mat-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="d-block d-md-none">            
              <hr class ="home-hr">
            </div>
          </div>
          
          <div class="carousel-right-content"
            [ngClass]="{'col-lg-4 col-md-4 col-sm-12': rxclaimOptin, 'col-lg-6 col-md-6 col-sm-12': !rxclaimOptin }">
            <div>
              <app-most-recent-claim [claimData]="claimData" [claimNumber]="sliderModel?.claim?.claimNumber"
                [claimStatus]="sliderModel?.claim?.status" [currentSlideNumber]="currentSlideNumber"
                [petName]="sliderModel?.policy?.pet.name" [policyNumber]="sliderModel?.policy?.policyNumber"
                [policyRenewal]="sliderModel?.policy?.renewalNumber" [renewal]="sliderModel?.claim?.renewal"
                [slideIndex]="idx" [statusBar]="sliderModel?.claim?.statusbar"
                [submittedDate]="sliderModel?.claim?.reportedDate">
              </app-most-recent-claim>
              
              <!-- <div class="d-block" *ngIf="isMPIPolicy && isClaimLoaded">  -->
                <div class="d-block" *ngIf="isMPIPolicy && isClaimLoaded"> 
                <hr class ="home-hr">              
                <app-benefits-deductible-gauge [policy]="sliderModel?.policy" [policyClaims]="policyClaims"
                  [isMyPetsPage]="false" [slideIndex]="idx" [coverageData]="''">
                </app-benefits-deductible-gauge>
              </div>
            </div>
            
            <div class="d-block d-md-none">  
              <hr class ="home-hr">            
            </div>
          </div>

          <div class="carousel-right-content text-left col-lg-4 col-md-4 sol-sm-12" *ngIf="rxclaimOptin">
            <div class="col-md-12">
              <app-insurance-id-card [policyDetails]="data" [memberName]="memberName" [nwInsuranceCardHeaderLogo]="nwHeaderLogoUrl" [cobrandingInsuranceCardHeaderLogo]="cobrandingHeaderLogoUrl"></app-insurance-id-card>
            </div>
          </div>

        </div>
      </slide>
    </carousel>
  </div>
  
  <div *ngIf="!policyDetailsList">
    <mat-card appearance="outlined">
      <mat-card-container>
        <p>Undergoing Routine Maintenance</p>
        <br>
        <p><b>We're sorry.</b> Our system is undergoing routine maintenance. Please attempt your transaction at a later
          time. Thank you for your patience.</p>
        <br>
        <p>{{content.validations.registerAccount.errorMessage}} </p>
      </mat-card-container>
    </mat-card>
  </div>
  <br>

  <!-- Banner Carousel for Desktop -->
  <div id="carouselDeskSlides" class="carousel slide ads--slider--desktop" data-ride="carousel" data-interval="6000">
    <div class="carousel-inner">
      <div *ngFor="let bnrImgs of bannerImagesArr;let i = index" class="carousel-item ads--item {{ (i == 0) ? 'active' : ''}}">
        <a href="{{bnrImgs.ImageLink}}" id="anchorTag-Sweeps-desktop">
          <img alt="sweepstakes banner" src="{{ bnrImgs.ImageUrl }}"/>
        </a>
        <div [innerHTML]="bnrImgs.ImageText" style="margin: 10px;"></div>
      </div>
    </div>
  </div>
  <!-- Banner Carousel for Mobile -->
  <div id="carouselMobSlides" class="carousel slide ads--slider--mobile" data-ride="carousel" data-interval="6000">
    <div class="carousel-inner">
      <div *ngFor="let bnrMobImgs of bannerMobileImagesArr;let i = index" class="carousel-item ads--item {{ (i == 0) ? 'active' : ''}}">
        <a href="{{bnrMobImgs.ImageLink}}" id="anchorTag-Sweeps-desktop">
          <img alt="sweepstakes banner" src="{{ bnrMobImgs.ImageUrl }}"/>
        </a>
        <div [innerHTML]="bnrMobImgs.ImageText" style="margin: 10px;"></div>
      </div>
    </div>
  </div>
  
  <app-homepage-footer [rxclaimOptin]="rxclaimOptin"></app-homepage-footer>
  
  <br />

  <div class="row justify-content-center">
    <div class="col-lg-8 col-md-10 col-sm-12">
      <div class="disclaimer_style"><b>Note</b>: {{dynamicContent?.homePage?.homePageFooter?.note}}</div>
    </div>

    <div class="col-lg-8 col-md-10 col-sm-12">
      <div class="disclaimer_style"><b><sup>&#8224;</sup>Claim submission authorization</b>: {{dynamicContent?.homePage?.homePageFooter?.claimSubmisstionAuth}}</div>
    </div>
  </div>
</div>

<app-progress-spinner class="example-margin homepage" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>