export const environment = {
  production: false,
  renbaseUrl: 'https://portal-extqa.petinsurance.com/api/',
  baseUrl: 'https://api-test.nwie.net/',
  orchBaseUrl: 'https://orchqaf.petinsurance.com/api/',
  accountsUrl: 'https://orchf.petinsurance.com/api/',
  qecBaseUrl: 'https://qecqa.petinsurance.com/',
  vpiBaseUrl: 'https://vpiqa.petinsurance.com/',
  reCaptchaEnabled: '#{{ENABLE_RECAPTCHA}}#',
  verifyReCaptchaUrl: 'https://api.nationwide.com/security-processing/recaptcha/v2/projects/recaptcha-270122/assessments',
  NWDeveloperClientId: 'jehmABYPA0chuJcpcjnCTmtli45zZsgL',
  reCaptchaSiteKey: '6Le1V98UAAAAAJELaV2_CZC8Y7pHmD7bB2pXLyGw',
  newRelic: '/assets/js/new-relic.js',
  referralUrl: ' https://www.petinsurance.com/referral',
  sweepStakesQecUrl: 'https://qecqa.petinsurance.com/?OM=PH0003',
  ensightenBootstrap: '//tags.nationwide.com/test/Bootstrap.js',
  nationwiideRedirectUrl: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize?client_id=' +
    '9R6VgQawx5JOj7KMGXvg42AscHD64qs5&redirect_uri=https://my-dev.petinsurance.com&response_type=token+id_token&scope=openid&nonce=1&auth_method=ping&realm=member&message_id=1',
  nationwiideRedirectCFUrl: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize?client_id=' +
    '9R6VgQawx5JOj7KMGXvg42AscHD64qs5&redirect_uri=https://df0zhgjb50nej.cloudfront.net&response_type=token+id_token&scope=openid&nonce=1&auth_method=ping&realm=member&message_id=1',
  //nationwideLogOut: 'https://devl-myaccount.nationwide.nwie.net/myaccount/Logout.action',
  nationwideLogOut: 'https://pt-myaccount.nationwide.com/myaccount/Logout.action',
  //nwUpdateUsernameAndPassword: 'http://devl-myaccount.nationwide.nwie.net/myaccount/profile/RetrieveUserContactInformation.action',
  nwUpdateUsernameAndPassword: 'https://st-myaccount.nationwide.nwie.net/myaccount/profile/RetrieveUserContactInformation.action',
  cardProfileUrl: 'https://apipci-test.nwie.net/financial-management/pet-paymentech-gateway/v1/cardprofiles',
  messageId: 'PetQuoteEnrollment',
  paymentGatewayClientId: '7OAieeR1lxKxuGIAp8z3oTja9D1oGEAk',
  portalContentful: '2YpgzgRqruKF99dFhjnaYJ',
  partnershipClientId: 'hH20YhFQmfsbAnCjVFQJWXGdm9lzs0jZ',
  partnershipAccessToken: 'fPocDVDKSSXcCADtAkv36HeEN6Jv', 
  partnershipMessageId: 'testfy',

  contentful: {
    space: '440y9b545yd9',
    environment: 'master',
    accessToken: '3vkS-gYKC4-cjB2Ba47AVT8y9KUaWZMrpDsNnc6sIas'
  },
  surchargeUrl: 'https://assets.ctfassets.net/440y9b545yd9/3W5gW5nYnUCbJSCYl5C8qY/95f2279022c33bdc3c38de085bca3bf1/State_Surcharge.json',

  nationwideLoginUrl: 'https://devl-login.nationwide.nwie.net/access/web/login.htm',
  nationwideFUPUrl: 'https://devl-login.nationwide.nwie.net/access/retrieve-username/verify-user.htm',
  nationwideCreateAccountUrl: 'https://devl-login.nationwide.nwie.net/access/register/signon-standard.htm',

  nationwideRefreshURL: 'https://identity-pt.nationwide.com/bsa-login-refresh/refresh?instance=BSAOAuthB2CNW&resumeURL=https://my-dev.petinsurance.com/refreshpage.html',
  nationwideRefreshTokenUrl: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize?client_id=' +
    '9R6VgQawx5JOj7KMGXvg42AscHD64qs5&redirect_uri=https://my-dev.petinsurance.com/refreshtoken.html&response_type=token+id_token&scope=openid&nonce=1&auth_method=ping&realm=member&message_id=1',
  beaconJs: '//cdn.evgnet.com/beacon/nationwidepet/nwpet_dev/scripts/evergage.min.js',
  client_id: 'SHlGmi9gh18AnFmcYS0x0WHJwjrzfqA7',
  splunkToken: '6AF26D9C-AFA8-483F-AB2D-569EE1CE63B9',
  applicationClientId: '7NNowOQQfbFeotAT2haE3IQkmtwZGak6',
  env: 'dev',
  euaV3Configuration: {
    authority: "https://api.identity-test.nwie.net/information-risk-management/enterprise-user-auth/v3/MEMBER-PET-UNUM",
    redirectUrl: "http://localhost:4200",
    postLogoutRedirectUri: "http://localhost:4200",
    clientId: "xWjSbq2uPhvTChHbIC0YGETrKfttAGJu",
    scope: "nwapi openid",
    responseType: "code",
    silentRenew: true,
    useRefreshToken: true,
  }
};
