<div *ngIf="showSideNav">
  <iframe src='../refreshpage.html' width="0" height="0" frameborder="0" title="Refreshpage Frame"></iframe>
  <iframe src='../refreshtoken.html' width="0" height="0" frameborder="0" title ="Refreshtoken Frame"></iframe>
</div>

<a class="skip-main" href="#main">Skip to main content</a>
<app-header [showSideNav]="showSideNav" [mppQuestions]="mppQuestions" (menuChange)="toggleMenu($event)" (loggedOut)="logout()"></app-header>
<mat-drawer-container autosize [ngClass]="{active: openMenu, 'no-menu': !showSideNav || mppQuestions}"  style="min-height: 100%;" role="main" id="main">
  <div [ngClass]="{'padding-nonclaims-ui': !checkClaimsUrl}"></div>
  <router-outlet (activate)="onActivate()"></router-outlet>
</mat-drawer-container>
<!-- <app-footer *ngIf="showSideNav"></app-footer> -->
<app-footer [showSideNav]="showSideNav" ></app-footer>

<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <h4 id="dialog-child-name" class="modal-title pull-left">You Have Been Idle!</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>     
      <div class="modal-body">
        <strong>{{ idleState }}</strong>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="stay()" class="btn btn-success">Stay</button>
      </div>
    </div>
  </div>
</div>
