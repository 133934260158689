<div class="my-renewal-wrapper container pt-3 pb-5">
  <div class="row">
    <div class="renewal-title">
      <app-navigation-back id="myPet-backbutton" (navigateBackEvent)="goToHomePage()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
      <h5 class="fw-bold" *ngIf="!renewalSuccessful">Renewal pet</h5>
      <h5 class="fw-bold" *ngIf="renewalSuccessful">Policy Renewed</h5>
    </div>
  </div>
  <hr style="border: 1px solid grey" />
  <h4 *ngIf="renewalSuccessful" class="sub-title">Congratulations.</h4>
  <p *ngIf="renewalSuccessful">Thank you for submitting your request to renew your Nationwide policy. Your request is currently being processed. An email confirming your renewal will be sent shortly.</p>
  <mat-card appearance="outlined">
    <strong *ngIf="!renewalSuccessful">Please Confirm Your Information</strong>
    <strong *ngIf="renewalSuccessful">Summary</strong>
    <mat-card-content>
      <br />
      <div class="row" *ngIf="policyRenewalData">
        <div class="col-md-6">
          <p><b>Payment Plan: </b> {{ policyRenewalData.pmtPlan === 'N' ? 'Annual' : 'Monthly' }}</p>
          <p><b>Payment Amount: </b> {{ policyRenewalData.amount }}</p>
          <p><b>Payment Type: </b> {{ policyRenewalData.payMethod === 'CC' ? 'Credit card' : 'Checking or Savings Account' }}</p>
          <p><b>Policy #: </b> {{ policyNumber }}</p>
          <!-- <p><b>Pet: </b> {{ policyDetailResponse?.petData?.name | titlecase }}</p> -->
          <p><b>Pet: </b> {{ petName | titlecase }}</p>
        </div>
        <div class="col-md-6" style="border-left: 1px solid black">
          <p>{{ policyRenewalData.firstName }} {{ policyRenewalData.lastName }}</p>
          <p>{{ policyRenewalData.address1 }} {{ policyRenewalData.address2 }}</p>
          <p>{{ policyRenewalData.city }}</p>
          <p>{{ policyRenewalData.state }}</p>
          <p>{{ policyRenewalData.zip }}</p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="pet-terms" *ngIf="!renewalSuccessful">
    <form [formGroup]="conditionTerms">
      <div class="form-check">
        <label class="form-check-label">
          <input type="checkbox" formControlName="authorize" class="form-check-input" value="true" />
          I hereby authorize the insurance provider to charge my credit card or electronic debit from the credit card company/financial institution indicated. The credit card company/financial institution is authorized to charge/debit and/or correct the amounts needed to pay for my premium as well
          as any applicable bank fees associated with insufficient funds in order to keep my insurance policy current. This authority shall remain in full force and effect until I revoke it with at least 10 days prior written notice or upon non-renewal by the insurance provider. Void where
          prohibited.
        </label>
        <div class="error" *ngIf="submitted && conditionTerms?.controls?.authorize?.errors?.required">
          This field is required.
        </div>
      </div>
      <div class="form-check">
        <label class="form-check-label">
          <input type="checkbox" formControlName="terms" id="termsAndCondition_id" class="form-check-input" value="true" />
          <!-- I have read and agree to the terms of the Privacy Policy and verify that I am at least 18 years of age and a resident of the U.S.A. -->
          I have read and agree to the <a href="javascript:void(0);" style="cursor:pointer" (click)="getTermsAndConditionModel()">Terms and Conditions</a> and the Privacy Policy and verify that i am at least 18 years of age and a resident of the U.S.A
        </label>
        <div class="error" *ngIf="submitted && conditionTerms?.controls?.terms?.errors?.required">
          This field is required.
        </div>
      </div>
    </form>
    <p>The above amount will be charged to your card when you click the Submit button. It may take a moment to process your request; please do not click Submit more than once.</p>
    <p *ngIf="policyRenewalData.state && policyRenewalData.state==='FL'" id="surchangeTitle_id" [innerHTML]="surchargeTitle"></p>
  </div>
  <div class="row">
    <!-- <div class="col-md-6">
      <app-navigation-back id='backbutton' *ngIf="!renewalSuccessful" (navigateBackEvent)="navigateBack()" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
      <app-navigation-back id='backbutton' *ngIf="renewalSuccessful" (navigateBackEvent)="goToHomePage()" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back> -->
    <div class="col-lg-6 col-md-6 col-sm-12 justify-content-start">
      <p *ngIf="renewalSuccessful" class="pt-2"><a href="javascript:void(0);" id="ach-download" style="cursor:pointer" (click)="downloadAgreement()">Print your Payment Authorization Agreement</a></p>
      <app-navigation-back id="backbutton" (navigateBackEvent)="goToHomePage()" class="pull-left pt-2 pl-1 d-none d-md-inline d-lg-inline"></app-navigation-back>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 text-end" *ngIf="!renewalSuccessful">
      <button mat-raised-button type="button" color="primary" class="custom-renew-button" (click)="submit()">Next</button>
    </div>
  </div>
</div>
<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"> </app-progress-spinner>