import {Component, Input, OnInit} from '@angular/core';
import {CONTENT} from '../../../content-management/content';
import {IMAGECONSTANTS} from '../../constants/image-constants';
import {Router, NavigationEnd} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../constants/routing-constants';
import { ContentfulService } from '../../services/contentful.service';
import CONTENTFULIDS from '../../constants/contentful-ids';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public content = CONTENT;
  public imageConstants = IMAGECONSTANTS;
  public routingConstants = ROUTINGCONSTANTS;
  public currentURL;
  public currentYear: number;
  public contentfulIds = CONTENTFULIDS;
  public footerTxtAfterLogin = '';
  public footerTxtBeforeLogin = '';
  
  @Input() showSideNav;

  constructor(private router: Router,
    private contentfulService: ContentfulService,) {
    this.getCurrentYear();
  }
  
  ngOnInit() {
    this.getFooterText();
    this.currentURL = this.router.url;
    this.router.events.subscribe(path => {
      if (path instanceof NavigationEnd) {
        this.currentURL = path.url;
      }
    });
  }
  
  getCurrentYear() {
    return this.currentYear = new Date().getFullYear();
  }
  
  goToHome() {
    this.router.navigate([this.routingConstants.home]);
  }
  
  goToClaims() {
    this.router.navigate([this.routingConstants.claims]);
  }
  
  goToPets() {
    this.router.navigate([this.routingConstants.pets]);
  }
  
  goToAccounts() {
    this.router.navigate([this.routingConstants.account]);
  }

  async getFooterText() {
    this.footerTxtAfterLogin = JSON.parse(sessionStorage.getItem('portalContenful')).footer.postlogin;
    this.footerTxtBeforeLogin = JSON.parse(sessionStorage.getItem('portalContenful')).footer.prelogin;
  }
}
