import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {AuthService} from './core/services/auth.service';
import {CommonService} from './shared/services/common.service';
import {GtmEventsService} from './shared/services/gtm-events.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import 'regenerator-runtime/runtime';
import {LoginResponse} from './models/response/login-response';
import {ValidateJwtRequest} from './models/request/validate-jwt-request';
import {ROUTINGCONSTANTS} from './shared/constants/routing-constants';
import {first} from 'rxjs/operators';
import {environment} from '../environments/environment';
import 'regenerator-runtime/runtime';
import {v4 as uuid} from 'uuid';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';
import { getCookieValue, setCookieValue, UNUM_COOKIE_INFO, UNUM_COOKIE_KEY } from './shared/helpers/helper-functions';

declare let newrelic: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  public openMenu = false;
  public idleState = "Not started.";
  public timedOut = false;
  public lastPing?: Date = null;
  public title = "angular-idle-timeout";
  public mppQuestions = false;
  public showSideNav = false;
  public tokenResponse: LoginResponse = {};
  public tokenRequest: ValidateJwtRequest = {};
  public routingConstants = ROUTINGCONSTANTS;
  public redirectUrl = environment.nationwiideRedirectUrl;
  public checkClaimsUrl : boolean = false;

  authorizedSubscriptions: Subscription[] = [];

  @ViewChild("childModal") childModal: ModalDirective;

  constructor(
    private router: Router,
    private idle: Idle,
    private route: ActivatedRoute,
    private keepalive: Keepalive,
    private gtmService: GtmEventsService,
    private authService: AuthService,
    private commonService: CommonService,
    public oidcSecurityService: OidcSecurityService
  ) {
    
    router.events.subscribe((val) => {
      this.checkNewClaimsUrl(this.router.url);
    });

    this.setRefreshURL();
    this.redirectToPetsFromCAM();
    this.checkLoggedIn();
    this.setIsLogin();
    idle.setIdle(2000);
    idle.setTimeout(30);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      this.reset();
    });
    this.checkIdleTimeout();
    this.checkForLoggedInStatus();
    this.setGTMPageLoadEvent();
    this.setNewRelicRouteTelemetry();
  }
  ngOnInit() {
    this.authorizedSubscriptions.push(
      this.oidcSecurityService
        .checkAuth()
        .subscribe((loginResponse) => {
          const { isAuthenticated, userData, accessToken, idToken, configId } = loginResponse;
          const url = window.location.href;
          const isUnumUser = getCookieValue(UNUM_COOKIE_KEY) ?  JSON.parse(getCookieValue(UNUM_COOKIE_KEY)) : false;
          if (url.includes("?error=access_denied")) {
            this.router.navigate([this.routingConstants.login]);
          } else if ((url.includes("?referrer=UNUM") && !isAuthenticated) || (isUnumUser && !isAuthenticated)) {
            this.oidcSecurityService.authorize();
          } else {
            if (isAuthenticated) {
              sessionStorage.setItem(
                "access_token",
                accessToken
              );
              
              sessionStorage.setItem("XNWMessageId", uuid());
              setCookieValue(UNUM_COOKIE_INFO);
              this.redirectToHomeOrPet(
                "portalUser",
                null,
                true
              );
            } 
          }
        })
    );
  }

  ngOnDestroy(): void {
    if (this.authorizedSubscriptions.length) {
      this.authorizedSubscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  checkNewClaimsUrl(claimsurl)
  {
    this.checkClaimsUrl = false;

    const routes = ['/new-claims-submission',
      '/new-claims-submission/pet-claims-info',
      '/new-claims-submission/invoice-upload',
      '/new-claims-submission/claimed-conditions',
      '/new-claims-submission/review-claim',
      '/new-claims-submission/claim-summary']

      this.checkClaimsUrl = routes.includes(claimsurl);
  }

  getRouteParamsAndRedirect() {
    const user = sessionStorage.getItem("isCamUser");
    let enterpriseToken = this.getEnterpriseTokenAndAssignToSessionStorage();
    if (user === "camUser") {
      window.open(this.redirectUrl, "_parent");
      this.redirectToHomeOrPet(user, enterpriseToken);
    } else {
      this.redirectToHomeOrPet(user, enterpriseToken);
    }
  }

  redirectToPetsFromCAM() {
    const user = sessionStorage.getItem("isPortalUser");
    const url = window.location.href;
    let enterpriseToken = this.getEnterpriseTokenAndAssignToSessionStorage();
    const camUser = url.includes("?referrer=CAM");
    if ((user !== "portalUser" && camUser) || camUser) {
      window.open(this.redirectUrl, "_parent");
    }
    this.redirectToHomeOrPet(user, enterpriseToken);
  }

  getRenJWTToken(token) {
    this.authService
      .validateRenEnterpriseJWTToken(token)
      .subscribe((tok: LoginResponse) => {
        if (tok.jwt) {
          sessionStorage.setItem("rencurrentUser", tok.jwt);
        }
      });
  }

  redirectToHomeOrPet(user, accessToken: string, isUnumUser = false) {
    // accessToken is always null in unum case since we grab it from oauth policy in the proxy
    if (accessToken || isUnumUser) {
      this.tokenRequest.JWT = accessToken;

      this.authService
        .validateEnterpriseJWTToken(this.tokenRequest)
        .pipe(first())
        .subscribe(
          (tok: LoginResponse) => {
            if (tok.jwt) {
              if (isUnumUser && tok.rsa_jwt) {
                this.getRenJWTToken({ JWT: tok.rsa_jwt});
              } else {
                this.getRenJWTToken(this.tokenRequest);
              }
              if (user === "portalUser") {
                this.router.navigate([this.routingConstants.home]);
                sessionStorage.removeItem("isPortalUser");
              } else {
                this.router.navigate([this.routingConstants.pets]);
              }
            }
          },
          (err) => {
            if (user === "portalUser") {
              this.router.navigate([this.routingConstants.home]);
              sessionStorage.removeItem("isPortalUser");
            } else {
              this.router.navigate([this.routingConstants.pets]);
            }
          }
        );
    }
  }

  checkLoggedIn() {
    const user = sessionStorage.getItem("currentUser");
    this.commonService.setSideNav(!!user);
  }

  setGTMPageLoadEvent() {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: "Portal Data Layer",
          eventCategory: "Page Load",
          eventAction: "Page Routing",
          eventLabel: "page URL",
          eventValue: item.url,
          userId: sessionStorage.getItem("userId"),
        };
        this.gtmService.pushGTMTags(gtmTag);
      }
    });
  }

  // Enhances BrowserInteraction's browserInteractionName by providing
  // proper url names, like 'login' or 'edit-payment/:policyNumber'
  setNewRelicRouteTelemetry() {
    this.router.events.subscribe((item) => {
      if (item instanceof NavigationEnd) {
        if (typeof newrelic !== "undefined") {
          newrelic.setCurrentRouteName(
            this.route.snapshot.firstChild.routeConfig.path
          );
          newrelic.interaction().save();
        }
      }
    });
  }

  checkIdleTimeout() {
    this.idle.onTimeout.subscribe(() => {
      this.childModal.hide();
      this.idleState = "Timed out!";
      this.timedOut = true;
      this.authService.logout();
      // this.router.navigate(['/']);
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      this.childModal.show();
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "You will time out in " + countdown + " seconds!";
    });
  }

  checkForLoggedInStatus() {
    this.keepalive.interval(10);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    this.authService.currentUserSubject.subscribe((userLoggedIn) => {
      if (userLoggedIn) {
        this.idle.watch();
        this.timedOut = false;
      } else {
        this.idle.stop();
      }
    });
  }

  setIsLogin() {
    this.commonService.showSideNav.subscribe((value) => {
      this.showSideNav = value;
    });
  }

  // setRoutingEvent() {
  //   this.router.events.subscribe(path => {
  //     if (path instanceof NavigationEnd) {
  //       localStorage.getItem('currentUser');
  //       if (path.url === '/' || path.url === '/login' || path.url === '/create-account' || path.url === '/registration' || path.url === '/forgot-id' || path.url === '/forgot-password') {
  //         sessionStorage.removeItem('currentUser');
  //       }
  //       this.isLogin = !sessionStorage.getItem('currentUser');
  //       this.mppQuestions = path.url === '/mpp-questions';
  //     }
  //   });
  // }

  onActivate() {
    window.scroll(0, 0);
  }

  toggleMenu(value) {
    this.openMenu = value;
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  logout() {
    this.openMenu = false;
    this.childModal.hide();
    this.authService.logout();
    // this.router.navigate(['/']);
  }

  setRefreshURL() {
    if (!sessionStorage.getItem("refreshurl")) {
      sessionStorage.setItem("refreshurl", environment.nationwideRefreshURL);
      sessionStorage.setItem(
        "refreshtokenurl",
        environment.nationwideRefreshTokenUrl
      );
    }
  }

  private getEnterpriseTokenAndAssignToSessionStorage() {
    let enterpriseToken = null;
    const url = window.location.href;
    if (url.lastIndexOf("&id_token=") !== -1) {
      enterpriseToken = url.substring(
        url.lastIndexOf("&id_token=") + 10,
        url.lastIndexOf("&state")
      );
      const accessToken = url.substring(
        url.lastIndexOf("access_token=") + 13,
        url.lastIndexOf("&token_type")
      );
      sessionStorage.setItem("access_token", accessToken);
      sessionStorage.setItem("XNWMessageId", uuid());
    }
    return enterpriseToken;
  }

}
